export class Price {
    value: number;

    constructor(value: number) {
        this.value = value;
    }

    format() {
        return (this.value / 100).toFixed(2);
    }
}