import {Moment} from "moment";

export class Timeslot {
    time: Moment;
    capacity: number;

    constructor(time: Moment, capacity: number) {
        this.time = time;
        this.capacity = capacity;
    }

    toString(): string {
        return this.time.format('YYYY-MM-DD HH:mm:ss') + ' (' + this.capacity + ')';
    }
}
